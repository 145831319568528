import * as React from 'react';

import Layout from '../../components/core/layout';
import Seo from '../../components/core/seo';
import H3 from '../../components/core/h3';
import P from '../../components/core/p';

import ProjectTitleContainer from '../../components/pages/projects/project-title';
import RowImageContainer from '../../components/pages/projects/row-image-container';
import TechStackContainer from '../../components/pages/projects/tech-stack/tech-stack-container';
import TechStackItemContainer from '../../components/pages/projects/tech-stack/tech-stack-item-container';

import SectionContainer from '../../components/core/section-container';
import ImgSnapcard1 from '../../components/images/projects/snapcard-mobile-1';
import ImgSnapcard3 from '../../components/images/projects/snapcard-mobile-3';
import TechnologyItem from '../../components/pages/projects/technology-item';
import SnapcardWeb from '../../components/images/projects/snapcard-web';
import SnapcardBusiness1 from '../../components/images/projects/snapcard-business-1';
import SnapcardBusiness2 from '../../components/images/projects/snapcard-business-2';

const Snapcard = () => (
  <Layout disableMenu>
    <Seo title="Snapcard" />
    <ProjectTitleContainer name="Snapcard" description="Get connected with your new friends easily." />
    <SectionContainer>
      <RowImageContainer>
        <ImgSnapcard1 />
        <ImgSnapcard3 />
      </RowImageContainer>
    </SectionContainer>
    <SectionContainer>
      <P>
        Collecting and storing contact information with boring business cards can sometimes be challenging, when you
        update your contact information, sending your new information to your friends can be considered a waste of time.
        When you trying to tell your e-mail address to someone you just meet, there may be confusion due to spelling and
        punction. By using Snapcard you can create a digital business card with your desired contact information.
        Snapcard creates a simple, unique and encyrpted QR Code with your information, if you want to share your
        information you can just simply show your QR Code. In this way, your partner can easily get your contact
        information and save it on their phone. If your new friend using Snapcard, you can get their information
        instantly without any actions. When you update your information your friends automatically receive update about
        your current information. Also Snapcard used by fair orginzers. Handling registration manually to fair couses
        time and money lose. By using Snapcard, the fair orginzers can gather participants information easily with using
        Snapcard's business specific modules. Snapcard offers to a fair orginzer is a handling collecting information
        from the participants, ananyomous fair analytics (which areas used more, how many of participants currently in
        the fair, most visited stands, and more).
      </P>
    </SectionContainer>
    <SectionContainer>
      <H3>Tech Stack</H3>
      <TechStackContainer>
        <TechStackItemContainer stackName="Application & Data" isFirstItem>
          <TechnologyItem technologyName="Gatsby.js" imgTag="gatsby" />
          <TechnologyItem technologyName="GraphQL" imgTag="graphql" />
          <TechnologyItem technologyName="Mongo DB" imgTag="mongodb" />
          <TechnologyItem technologyName="Node JS" imgTag="nodejs" />
          <TechnologyItem technologyName="Tailwind CSS" imgTag="tailwindcss" />
        </TechStackItemContainer>
        <TechStackContainer>
          <TechStackItemContainer stackName="Utilities">
            <TechnologyItem technologyName="Swagger" imgTag="swagger" />
            <TechnologyItem technologyName="Postman" imgTag="postman" />
            <TechnologyItem technologyName="Analytics" imgTag="analytics" />
          </TechStackItemContainer>
        </TechStackContainer>
        <TechStackContainer>
          <TechStackItemContainer stackName="DevOps">
            <TechnologyItem technologyName="VS Code" imgTag="vscode" />
            <TechnologyItem technologyName="Github" imgTag="github" />
            <TechnologyItem technologyName="Git" imgTag="git" />
            <TechnologyItem technologyName="AWS Amplify" imgTag="amplify" />
            <TechnologyItem technologyName="Yarn" imgTag="yarn" />
          </TechStackItemContainer>
        </TechStackContainer>
        <TechStackContainer>
          <TechStackItemContainer stackName="Business Tools">
            <TechnologyItem technologyName="Slack" imgTag="slack" />
            <TechnologyItem technologyName="Figma" imgTag="figma" />
            <TechnologyItem technologyName="ClickUp" imgTag="clickUp" />
            <TechnologyItem technologyName="Abstract" imgTag="abstract" />
            <TechnologyItem technologyName="Miro" imgTag="miro" />
          </TechStackItemContainer>
        </TechStackContainer>
      </TechStackContainer>
    </SectionContainer>
    <SectionContainer>
      <H3>About Journey</H3>
      <div className="pt-2">
        <P>
          Designed and developed Snapcard's web and mobile applications. Almost every week I published new features for
          Snapcard and Snapcard used by +15 countries. Precisely documented the code, used Agile practices, and Test
          Driven Development techniques to provide reliable, working software early and often. Built strategic marketing
          and business development plans. Using complex reporting/tracking tools to gather and evaluate the data to
          perfect the user experience and improve the performance of the systems we use.
        </P>
      </div>
    </SectionContainer>
    <SectionContainer>
      <H3>Product</H3>
      <ul className="pt-2">
        <li className="pt-4">
          <SnapcardWeb />
        </li>
        <li className="pt-4">
          <SnapcardBusiness1 />
        </li>
        <li className="pt-4">
          <SnapcardBusiness2 />
        </li>
      </ul>
    </SectionContainer>
  </Layout>
);

export default Snapcard;
